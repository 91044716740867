.vanilla .logo-container {
  display: none !important;
}
.vanilla .vanilla-header {
  width: 100%;
  margin: 50px auto -15px auto !important;
}
.vanilla .vanilla-header.Register {
  color: #06214F;
}
.vanilla .vanilla-header.Login,
.vanilla .vanilla-header.Reset.Password {
  color: #06214F;
  width: 391px !important;
}
@media screen and (max-width: 767px) {
  .vanilla .vanilla-header.Login,
  .vanilla .vanilla-header.Reset.Password {
    width: 100% !important;
  }
}
.vanilla .login-container {
  margin-top: -30px;
}
@media screen and (max-width: 767px) {
  .vanilla .login-container {
    margin-top: 0 !important;
  }
}
.vanilla .scm-layout .right.menu .item.active,
.vanilla .admin-layout .right.menu .item.active,
.vanilla .scm-layout .right.menu .text.active,
.vanilla .admin-layout .right.menu .text.active {
  color: #30C7C2 !important;
}
.vanilla .scm-layout .right.menu .item.active:hover,
.vanilla .admin-layout .right.menu .item.active:hover,
.vanilla .scm-layout .right.menu .text.active:hover,
.vanilla .admin-layout .right.menu .text.active:hover {
  color: #30C7C2 !important;
}
.vanilla .scm-layout .right.menu .item:hover,
.vanilla .admin-layout .right.menu .item:hover,
.vanilla .scm-layout .right.menu .text:hover,
.vanilla .admin-layout .right.menu .text:hover {
  color: #30C7C2 !important;
}
.vanilla .sme-layout .item:hover {
  border-bottom-color: #30C7C2 !important;
}
.vanilla .anchor:hover .video-title {
  color: #30C7C2 !important;
}
.vanilla .main-content .side.menu .item {
  color: #06214F;
}
.vanilla .main-content .side.menu .item.active,
.vanilla .main-content .side.menu .item:hover {
  color: #30C7C2 !important;
}
.vanilla .main-content .active-qualification {
  background-color: #06214F !important;
}
.vanilla .dark-orange,
.vanilla .role-button.selected-role-button,
.vanilla .request-grading {
  background-color: #FBB02A !important;
}
.vanilla .dark-orange .counter-badge,
.vanilla .role-button.selected-role-button .counter-badge,
.vanilla .request-grading .counter-badge {
  border-color: #FBB02A;
  color: #FBB02A;
}
.vanilla .button-counter.role-button:not(.selected-role-button) .counter-badge {
  background-color: #FBB02A;
  border-color: #FBB02A;
}
.vanilla .ui.button.primary {
  background-color: #30C7C2 !important;
}
.vanilla .ui.button.primary:hover {
  box-shadow: 0 1px 20px #00000024 !important;
}
.vanilla .ui.button.secondary {
  background-color: #06214F !important;
}
.vanilla .ui.button.secondary:hover {
  box-shadow: 0 1px 20px #00000024 !important;
}
.vanilla .ui.button.request-button {
  background-color: #FBB02A !important;
}
.vanilla .ui.button.filter-expander {
  color: #30C7C2 !important;
}
.vanilla .ui.selection.dropdown:hover {
  border-color: #30C7C2 !important;
}
.vanilla .sme-layout .top-menu,
.vanilla .menu-container,
.vanilla .sme-progress-bar {
  background-color: #06214F !important;
}
.vanilla .left-nav > .nav-content .item.active {
  background: #06214F !important;
}
.vanilla .company-details-table .table-content .four.column.row {
  background-color: #06214F !important;
}
.vanilla table thead tr th {
  background-color: #06214F !important;
}
.vanilla table.table-st-1 thead th {
  border-right: 1px solid rgba(0, 0, 0, 0.06) !important;
}
.vanilla table tbody .title .one.wide.text-center > a {
  color: #30C7C2 !important;
}
.vanilla .scm-layout .small-button,
.vanilla .admin-layout .small-button {
  background-color: #30C7C2 !important;
}
.vanilla .scm-layout .converstation .recieved-message .initials,
.vanilla .admin-layout .converstation .recieved-message .initials {
  background-color: #06214F !important;
}
.vanilla .scm-layout .converstation .sent-message .initials,
.vanilla .admin-layout .converstation .sent-message .initials {
  background-color: #FBB02A !important;
}
.vanilla .scm-layout .converstation .sent-message .message,
.vanilla .admin-layout .converstation .sent-message .message {
  background-color: #30C7C2 !important;
}
.vanilla .ui.toggle.checkbox input:checked ~ label:before {
  background-color: #30C7C2 !important;
}
.vanilla .header-content .active-tab {
  background-color: #06214F;
}
.vanilla .industries-header {
  background-color: #06214F !important;
}
.vanilla .ui .button-link,
.vanilla .button-link {
  color: #00AEEF !important;
}
.vanilla .ui .button-link:hover,
.vanilla .button-link:hover {
  color: #06214F !important;
}
