.zevoli .welcome-header {
  visibility: hidden !important;
  height: 0;
  margin: 0;
}
.zevoli .scm-layout .right.menu .item.active,
.zevoli .admin-layout .right.menu .item.active,
.zevoli .scm-layout .right.menu .text.active,
.zevoli .admin-layout .right.menu .text.active {
  color: #00aeef !important;
}
.zevoli .scm-layout .right.menu .item.active:hover,
.zevoli .admin-layout .right.menu .item.active:hover,
.zevoli .scm-layout .right.menu .text.active:hover,
.zevoli .admin-layout .right.menu .text.active:hover {
  color: #00aeef !important;
}
.zevoli .scm-layout .right.menu .item:hover,
.zevoli .admin-layout .right.menu .item:hover,
.zevoli .scm-layout .right.menu .text:hover,
.zevoli .admin-layout .right.menu .text:hover {
  color: #00aeef !important;
}
.zevoli .vanilla-header {
  display: none !important;
}
.zevoli .ui .button-link,
.zevoli .button-link {
  color: #00aeef !important;
}
.zevoli .ui .button-link:hover,
.zevoli .button-link:hover {
  color: #033b61 !important;
}
