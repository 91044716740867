@lightBlue: #30C7C2 ;
@darkBlue: #06214F;
@yellow: #FBB02A;

.vanilla {
    .logo-container  {
        display: none !important;
    }
    .vanilla-header {
        width: 100%;
        margin: 50px auto -15px auto !important;
        &.Register { color: @darkBlue; }
        &.Login, 
        &.Reset.Password {
            color: @darkBlue;
            width: 391px !important;
            @media screen and (max-width: 767px){
                width: 100% !important;
            }
        }
    }
    .login-container {
        margin-top: -30px ;
        @media screen and (max-width: 767px){
            margin-top: 0 !important;
        }
    }
    .scm-layout, .admin-layout {
        .right.menu {
            .item, .text {
                &.active {
                    color: @lightBlue !important;
                    &:hover {
                        color: @lightBlue !important;
                    }
                }
                &:hover {
                    color: @lightBlue !important;
                }
            }
        }
    }
    .sme-layout {
        .item:hover {
            border-bottom-color: @lightBlue !important;
        }
    }
    .anchor:hover {
        .video-title {
            color: @lightBlue !important
        }
    }
    .main-content {
        .side.menu {
            .item {
                color: @darkBlue;
                &.active, &:hover {
                    color: @lightBlue !important
                }
            }
        }
       
        .active-qualification {
            background-color: @darkBlue !important;
        }
    }
    .dark-orange, .role-button.selected-role-button, .request-grading {
        background-color: @yellow !important;
        .counter-badge {
            border-color: @yellow;
            color: @yellow;
        }
    }
    .button-counter.role-button:not(.selected-role-button) .counter-badge {
        background-color: @yellow;
        border-color: @yellow;
    }
    .ui.button {
        &.primary {
            background-color: @lightBlue !important;
            &:hover {
                box-shadow: 0 1px 20px #00000024 !important;
            }
        }
        &.secondary {
            background-color: @darkBlue !important;
            &:hover {
                box-shadow: 0 1px 20px #00000024 !important;
            }
        }
        &.request-button {
            background-color: @yellow !important;
        }
        &.filter-expander {
            color: @lightBlue !important
        }
    }
    .ui.selection.dropdown:hover {
        border-color: @lightBlue !important;
    }
    .sme-layout .top-menu, .menu-container, .sme-progress-bar {
        background-color: @darkBlue !important;
    }
    .left-nav > .nav-content {
        .item.active {
            background: @darkBlue !important;
        }
    }
    .company-details-table {
        .table-content {
            .four.column.row {
                background-color: @darkBlue !important;
            }
        }
    }
    table {
        thead tr th{
            background-color: @darkBlue !important;
        }
        &.table-st-1 {
            thead th {
                border-right: 1px solid rgba(0,0,0,0.06) !important;
            }
        }
        tbody {
            .title .one.wide.text-center > a {
                color: @lightBlue !important;
            }
        }
    }
    .scm-layout, .admin-layout {
        .small-button {
            background-color: @lightBlue !important;
        }
        .converstation {
            .recieved-message {
                .initials {
                    background-color: @darkBlue !important;
                }
            }
            .sent-message {
                .initials {
                    background-color: @yellow !important;
                }
                .message {
                    background-color: @lightBlue !important;
                }
            }
        }
    }
    .ui.toggle.checkbox input:checked ~ label:before {
        background-color: @lightBlue !important;
    }
    .header-content {
        .active-tab {
            background-color: @darkBlue;
        }
    }
    .industries-header {
        background-color: @darkBlue !important;
    }

    .ui .button-link,
    .button-link {
        color: #00AEEF !important;
        &:hover {
            color: @darkBlue !important;
        }
    }
}