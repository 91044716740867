@lightBlue: #00aeef ;
@darkBlue: #033b61;

.zevoli {
    .welcome-header {
        visibility: hidden !important;
        height: 0;
        margin: 0;
    }
    .scm-layout, .admin-layout {
        .right.menu {
            .item, .text {
                &.active {
                    color: @lightBlue !important;
                    &:hover {
                        color: @lightBlue !important;
                    }
                }
                &:hover {
                    color: @lightBlue !important;
                }
            }
        }
    }
    .vanilla-header {
        display: none !important;
    }

    .ui .button-link,
    .button-link {
        color: @lightBlue !important;
        &:hover {
            color: @darkBlue !important;
        }
    }

}